/* eslint-disable no-console,no-undef,camelcase */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { HeadingContainer, HeadingMain } from '../MainEvents/OnDemandDetailsPage/style'
import { FirstContainer, FormContainer, FieldTitle, ButtonContainer } from '../MainEvents/CreateOndemandClass/style';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// import { teamPeoples, createTeamOptions } from '../../../mockData.json';
import { addChallengeTeams, fetchCompanyTeamUsers, fetchUsersToAddToTeam, fetchCompanyTeamCount } from '../../redux/actions';
import { ActivityDropdown, CustomMenuItem, DropdownArrow, TitleContainer, OnDemandButton, StyledProgressBar, StyledInputNew, StyledCSVModal, CustomTitle, StyledModalCreateTeams, Layout, Main } from './styles';
import { Modal, Popover } from "react-bootstrap";
import { toast } from 'react-toastify';
import _ from 'lodash';
import Waiting from '../Waiting';

class AdminTeamCreationForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      url: '',
      time: '',
      description: '',
      imageName: '',
      imgSrc: '',
      selectPeopleKey: '1',
      selectPeopleValue: "Select People",
      pointValue: '50',
      coachId: '',
      flayerName: '',
      buttonstatus: false,
      NoOfTeams: "",
      selectTeamValue: 'Select Options',
      teamType: '',
      companyId: props.companyInfo['id'],
      sp: false,
      so: false,
      showModal: false,
      showCsvModal: false,
      progress: 0,
      timeout: false,
      nextButtonStatus: false,
      dissabledSubmit: true,
      csvBase64: "",
      csvTeam: 0,
      fileName: '',
      submitButtonStatus: false,
      showUpload: false,
      hideReupload: true
    }
  }

  teamPeoples= [
    {"id": "1", "value": "3"}, 
    {"id": "2", "value": "4"},
    {"id": "3", "value": "5"},
    {"id": "4", "value": "6"}, 
    {"id": "5", "value": "7"},
    {"id": "6", "value": "8"},
    {"id": "7", "value": "9"}, 
    {"id": "8", "value": "10"},
    {"id": "9", "value": "11"},
    {"id": "10", "value": "12"}, 
    {"id": "11", "value": "13"},
    {"id": "12", "value": "14"},  
    {"id": "13", "value": "15"}, 
    {"id": "14", "value": "16"},
    {"id": "15", "value": "17"},  
    {"id": "16", "value": "18"}, 
    {"id": "17", "value": "19"},
    {"id": "18", "value": "20"},  
    {"id": "19", "value": "21"}, 
    {"id": "20", "value": "22"},
    {"id": "21", "value": "23"},  
    {"id": "22", "value": "24"}, 
    {"id": "23", "value": "25"}   
  ]

  createTeamOptions= [
    {"type": "manual", "value": "Manual"}
  ]

  componentDidMount() {
    let obj = {};
    obj['company_id'] = this.state.companyId;
    this.props.fetchUsersToAddToTeam(obj);
    if(!_.isNull(this.props.teamsCount)){
      if(this.props.teamsCount && this.props.teamsCount['team_count']>0){
        this.props.history.push('/company/people/team')
      }
    }
  }

  componentDidUpdate() {
    this.state.timeout && this.state.progress < 100 && setTimeout(this.progress, 80);
  }

  onChangeURL = (e) => {
    this.setState({
      url: e.target.value,
    });
  };

  onChangeTime = (e) => {
    const numValidate = /^[0-9\b]+$/;
    if (e.target.value === '' || numValidate.test(e.target.value)) {
      this.setState({ time: e.target.value })
    }
  };
  onHide = () => {
    this.setState({ showModal: false })
  }
  onCsvHide = () => {
    this.setState({ showCsvModal: false })
  }

  onSelectPeople = (name, value) => {
    this.setState({
      [name]: value
    });
    this.handleTeamSize(value);
  };

  onSelectTeamType = (name, value, type) => {
    this.setState({
      [name]: value,
      teamType: type
    });
  };

  progress = () => {
    this.setState({ progress: this.state.progress + 3 })
  }

  handleTeamSize = (value) => {
    if (this.props.employeeCount > 0 && (value > 0)) {
      let size = 0;
      size = this.props.employeeCount / value;
      this.setState({ NoOfTeams: Math.round(size) })
    }
  }

  uploadCSVFile = (e) => {
    let file = e.target.files[0];
    if (file) {
      let fileArr = file.name.split('.');
      if (fileArr[fileArr.length - 1].toUpperCase() == 'XLSX') {
        if ((file?.size / 1000000) <= 10) {
          let reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onloadend = () => {
            this.setState({
              csvBase64: reader.result,
              dissabledSubmit: false,
              fileName: file.name
            })
          };
        } else {
          toast.error('Please select CSV file less than 10MB');
        }
      } else {
        toast.error('Please select CSV file');
      }
    }
  };

  saveCreateTeamDetails = () => {
    const { companyId, buttonstatus, showModal, NoOfTeams, selectPeopleValue } = this.state;
    const { addChallengeTeams } = this.props;
    this.setState({
      timeout: true,
      buttonstatus: !buttonstatus,
      showModal: !showModal
    });
    const TeamDetails = {
      user_count: selectPeopleValue,
      team_count: NoOfTeams,
      team_creation_type: "manual",
      company_id: companyId
    };
    addChallengeTeams(TeamDetails, companyId);
  };

  openCsvModal = () => {
    this.setState({
      showCsvModal: true
    })
  }
  popover = () => (
    <Popover id="popover-basic"
      style={{
        width: "100%",
        padding: "9px 14px",
        background: "#FFFFFF",
        border: "1px solid #0D4270",
        boxShadow: "0px 13px 28px rgba(0, 0, 0, 0.3)",
        borderRadius: "6px",
      }}
    >
      <div style={{
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "20px",
        color: "#0D4270",
        textAlign: "left"
      }}>Please check All the Fields are Correct The
                Way you Have share with Woliba should
                match That Like First name , Last name ,
                Email, Department, Location and
                Teams Name.
      </div>
    </Popover>);

  handleCsvEvent = () => {
    const { companyId, buttonstatus, NoOfTeams, csvBase64, selectPeopleValue, showUpload } = this.state;
    const { addChallengeTeams } = this.props;
    this.setState({
      showCsvModal: false,
      timeout: true,
      buttonstatus: !buttonstatus,
      csvTeam: 1,
      submitButtonStatus: true
    });
    if (showUpload) {
      this.setState({
        showUpload: !showUpload
      })
    }
    const TeamDetails = {
      user_count: selectPeopleValue,
      csv_file: csvBase64,
      team_count: NoOfTeams,
      team_creation_type: "csv",
      company_id: companyId
    };
    addChallengeTeams(TeamDetails, companyId);
  }

  removeCSV = () => {
    this.setState({
      csvBase64: "",
      dissabledSubmit: true,
      fileName: ""
    });
  }

  reuploadCSV = () => {
    this.setState({
      csvBase64: "",
      dissabledSubmit: true,
      fileName: "",
      showUpload: true
    });
  }

  hideReuploadPopup = () => {
    this.setState({
      hideReupload: false,
      csvBase64: "",
      dissabledSubmit: true,
      fileName: ""
    })
  }

  render() {
    const { selectPeopleValue, nextButtonStatus, showCsvModal, NoOfTeams, selectTeamValue, showModal, dissabledSubmit, csvTeam, fileName, submitButtonStatus, showUpload, hideReupload } = this.state;
    const { employeeCount, teamsCreatedStatus } = this.props;
    let csvTeamStatus;
    let csvNotUploaded;
    if (submitButtonStatus && _.isUndefined(teamsCreatedStatus)) {
      return <Waiting />
    }
    if (submitButtonStatus && !_.isNull(teamsCreatedStatus)) {
      csvTeamStatus = teamsCreatedStatus;
      if (csvTeamStatus !== "Data added successfully.") {
        csvNotUploaded = true
      }
    }

    return (
      <Layout>
        <Main>
          <FirstContainer>
            <FormContainer editChallenge={"1"} >
              <HeadingContainer width={"100%"} margin={'15px'}>
                <HeadingMain>Create A Wellness Teams</HeadingMain>
              </HeadingContainer>
              <TitleContainer>
                <FieldTitle>{"Total Enrolled Users"}</FieldTitle>
                <StyledInputNew
                  placeholder="Auto Select Based On Company Location/Department"
                  name="title"
                  value={employeeCount}
                  type="number"
                  disabled
                />
              </TitleContainer>
              <TitleContainer>
                <FieldTitle>{"How many people would you like in each team? (3 min - 25 max)"}</FieldTitle>
                <DropdownArrow alt='image' drop={this.state.sp} src={this.state.sp ? "/public/images/teamDownArrow.svg" : "/public/images/dropdown_arrow.svg"} />
                <ActivityDropdown
                  title={selectPeopleValue}
                  id="dropdown-recurring"
                  onClick={() => this.setState({ sp: !this.state.sp })}
                >
                  {
                    this.teamPeoples.map((item, index) => (
                      <CustomMenuItem
                        eventKey={index}
                        key={index}
                        onClick={() => this.onSelectPeople('selectPeopleValue', item['value'])}
                        active={selectPeopleValue == item['value']}
                      >
                        {item['value']}
                      </CustomMenuItem>
                    ))
                  }
                </ActivityDropdown>
              </TitleContainer>
              <TitleContainer>
                <FieldTitle>{"Number Of Teams"}</FieldTitle>
                <StyledInputNew
                  placeholder="Auto Filled Out"
                  name="title"
                  value={NoOfTeams}
                  disabled
                />
              </TitleContainer>
              <TitleContainer fullWidth>
                <FieldTitle>{"How would you like to create teams?"}</FieldTitle>
                <DropdownArrow alt='image' drop={this.state.so} src={this.state.so ? "/public/images/teamDownArrow.svg" : "/public/images/dropdown_arrow.svg"} />
                <ActivityDropdown
                  placeholder="Select Option"
                  title={selectTeamValue}
                  id="dropdown-recurring"
                  onClick={() => this.setState({ so: !this.state.so })}
                >
                  {
                    this.createTeamOptions.map((item, index) => (
                      <CustomMenuItem
                        eventKey={index}
                        key={index}
                        onClick={() => this.onSelectTeamType('selectTeamValue', item['value'], item['type'])}
                        active={selectPeopleValue == item['value']}
                      >
                        {item['value']}
                      </CustomMenuItem>
                    ))
                  }
                </ActivityDropdown>
              </TitleContainer>
              <ButtonContainer>
                {selectTeamValue != "Select Options" && selectPeopleValue > 0 && this.props.employeeCount > 2 && (selectPeopleValue < employeeCount) ?
                  <OnDemandButton onClick={() => selectTeamValue == "Manual" ? this.saveCreateTeamDetails() : this.openCsvModal()}>
                    <span>
                      Create Teams
                    </span>
                  </OnDemandButton> :
                  <OnDemandButton disabled={true} >
                    <span>
                      Create Teams
                    </span>
                  </OnDemandButton>
                }
              </ButtonContainer>
            </FormContainer>
          </FirstContainer>
          <StyledModalCreateTeams
            show={selectTeamValue === "Upload Csv" ? csvTeamStatus && !_.isUndefined(csvTeamStatus) && csvTeamStatus === "Data added successfully." ? true : false : showModal}
            dialogClassName="modal-90w"
          >
            <Modal.Body>
              {csvTeam ? <h2 className='csvH2'>Creating Teams</h2> : <h2>Wellness Teams</h2>}
              {this.state.progress < 100 ? csvTeam ? <h3> Thank you!<br /> Stay turned while we create your teams... </h3> : <h3> We are creating your Wellness teams.</h3> :
                <h3> We are have created your <br />wellness teams successfully...</h3>}
              <img src={this.state.progress < 100 ? '/public/images/loadingTeam.gif' : "/public/images/correctTeam.svg"} />
              <StyledProgressBar animated now={this.state.progress} />
              {this.state.progress < 100 && <h4>Please give us a few mins...</h4>}
            </Modal.Body>
            {this.state.progress > 100 && <Modal.Footer>
              <div onClick={() => {
                this.props.history.push('/company/people/team')
              }}>View Teams</div>
            </Modal.Footer>}
          </StyledModalCreateTeams>
          <StyledCSVModal
            show={submitButtonStatus && csvNotUploaded && hideReupload ? true : showCsvModal}
            dialogClassName="modal-90w"
            dissabledSubmit={dissabledSubmit}
          >
            <Modal.Header>
              <CustomTitle>
                <div>
                  <span>{("Upload Teams CSV")}</span>
                  <span className="closeImage" onClick={() => submitButtonStatus && csvNotUploaded && hideReupload ? this.hideReuploadPopup() : this.onCsvHide()}><img src='/public/images/new_close_icon.png' /></span>
                </div>
              </CustomTitle></Modal.Header>

            {submitButtonStatus && csvNotUploaded && !showUpload ?
              <Modal.Body>
                <div className="cardWrapper">
                  <div className="text">Teams CSV (Upload CSV)</div>
                  <a className="downloadText" href='/public/downloads/TeamCreationSheet.xlsx' >Download Template</a>
                </div>
                <div className="modalBody">
                  <div className="uploadCsv">
                    <div className="imageWrapperNotUpload">
                      <img src='/public/images/AdminTeam/notUploaded.png' />
                    </div>
                  </div>
                  <div className="notUploadText">{`Uploaded ${fileName} is not correct. Please Check and Reupload`}</div>
                  <div className="deleteButton">
                    <div className="button" onClick={() => this.reuploadCSV()}>Reupload</div>
                  </div>
                </div>
              </Modal.Body> :

              nextButtonStatus ? <Modal.Body>
                <h2>Click On the Upload File button below to add Your team Csv.</h2>
                <div className='uploadCsvDiv'>
                  <div href='/public/downloads/TeamCreationSheet.xlsx' className='uploadCsvBtn' >
                    <img src='/public/images/AdminTeam/uploadCsv.svg'></img>
                    <h4>Upload Team CSV</h4>
                    <input
                      id="profle-file"
                      type="file"
                      name="user-profile"
                      multiple={false}
                      accept=".xlsx"
                      onChange={(e) => this.uploadCSVFile(e)}
                      onClick={(e) => e.target.files[0] && this.uploadCSVFile(e)}
                    /></div>
                  {!dissabledSubmit && <h5>Uploaded</h5>}
                </div>
              </Modal.Body> : <Modal.Body>
                <div className="cardWrapper">
                  <div className="text">Teams CSV (Upload CSV)</div>
                  <a className="downloadText" href='/public/downloads/TeamCreationSheet.xlsx' >Download Template</a>
                </div>
                <div className="modalBody">
                  {fileName ?
                    <div className="uploadCsv">
                      <div className="imageWrapperUpload">
                        <img src='/public/images/AdminTeam/uploaded.png' />
                      </div>
                    </div>:
                    <div className="uploadCsv">
                      <div className="imageWrapper">
                        <img src='/public/images/AdminTeam/UploadIcon.png' />
                        <input
                          id="profle-file"
                          type="file"
                          name="user-profile"
                          multiple={false}
                          accept=".xlsx"
                          onChange={(e) => this.uploadCSVFile(e)}
                          onClick={(e) => e.target.files[0] && this.uploadCSVFile(e)}
                        />
                      </div>
                    </div>}
                  {fileName ?<div className="uploadText">{`Uploaded ${fileName} Looks Good!`}</div>:<div className="selectText">Select a CSV file to upload</div>}
                  {fileName ? <div className="deleteButton">
                    <div className="button" onClick={() => this.removeCSV()}>Delete</div>
                  </div> : null}
                </div>
              </Modal.Body>}
            {submitButtonStatus && csvNotUploaded && !showUpload ?null:fileName ? <Modal.Footer>
              {fileName ? <div className="sumbitButton" disabled={dissabledSubmit} onClick={() => this.handleCsvEvent()} >Submit To Create Teams</div> : null}
            </Modal.Footer> : null}
          </StyledCSVModal>
        </Main>
      </Layout>
    )
  }
}

AdminTeamCreationForm.defaultProps = {
  companyInfo: { id: 1, name: '' }
};

AdminTeamCreationForm.propTypes = {
  createOndemand: PropTypes.func.isRequired,
  ondemandCategory: PropTypes.array,
  addChallengeTeams: PropTypes.func,
  fetchCompanyTeamUsers: PropTypes.func,
  companyInfo: PropTypes.object,
  history: PropTypes.object.isRequired,
  teamUsers: PropTypes.array,
  fetchUsersToAddToTeam: PropTypes.func,
  employeeCount: PropTypes.array,
  teamsCreatedStatus: PropTypes.number,
  fetchCompanyTeamCount: PropTypes.func,
  teamsCount: PropTypes.number
};

const mapStateToProps = (state) => ({
  teamUsers: state.adminTeamCreation.teamUsers,
  teamsCreatedStatus: state.adminTeamCreation.teamsCreatedStatus
});

const mapDispatchToProps = (dispatch) => ({
  fetchUsersToAddToTeam: (object) => dispatch(fetchUsersToAddToTeam(object)),
  addChallengeTeams: (obj, companyId) => dispatch(addChallengeTeams(obj, companyId)),
  fetchCompanyTeamUsers: (companyId) => dispatch(fetchCompanyTeamUsers(companyId)),
  fetchCompanyTeamCount: (companyId) => dispatch(fetchCompanyTeamCount(companyId))
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AdminTeamCreationForm));